import React from "react";
import * as PropTypes from "prop-types";
import { Box } from "@chakra-ui/core";
import VenueType from "./venueTypes";
import {
  FiMapPin
} from "react-icons/fi";
import VenueSelector from "./venueSelector"
import Schedule from "../places/schedule"

const LineAddress = ({
  venue, venues, changeVenue, mt, mb, collapse, ...props
}) => {
  if (venue) {
    const line = venues.length > 1 ? (
      <VenueSelector
        venues={venues}
        venue={venue}
        changeVenue={changeVenue}
        mr={2}
        d="inline-block"
        size="xs"
      />
    ) : null;
    return (
      <Box mb={mb} textAlign="center" {...props}>
        <Box mt={mt} mb={mb} d="inline-block">
          {line}
          <Box mt={[2, 2, 0, 0]} d="inline-block">
            {venue.address}
            <Box d="inline" as={FiMapPin} color="red.500" ml={1} />
          </Box>
        </Box>
        <Schedule ml={[0, 4, 8, 8]} venue={venue} collapse={collapse} />
      </Box>
    );
  }
  return null;
};

LineAddress.propTypes = {
  venue: VenueType,
  venues: PropTypes.arrayOf(VenueType),
  changeVenue: PropTypes.func.isRequired,
  collapse: PropTypes.bool,
};

LineAddress.defaultProps = {
  collapse: false,
};

export default LineAddress;
