import React from "react";
import * as PropTypes from "prop-types";
import { } from "@chakra-ui/core";
import VenueType from "./venueTypes";
import {
  Menu, MenuButton, MenuList, MenuItem, Button,
} from "@chakra-ui/core";

const VenueSelector = ({
  venue, venues, changeVenue, ...props
}) => {
  const theVenues = venues.filter(v => v.name !== venue.name);
  return (
    <Menu>
      <MenuButton as={Button} rightIcon="arrow-up-down" {...props}>
        {venue.name}
      </MenuButton>
      <MenuList maxHeight={200} overflowY="auto">
        {theVenues.map(v => (
          <MenuItem key={v.name} onClick={() => changeVenue(v)}>
            {v.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

VenueSelector.propTypes = {
  venues: PropTypes.arrayOf(VenueType),
  venue: VenueType,
  changeVenue: PropTypes.func.isRequired,
};

export default VenueSelector;
