import React from "react";
import MainLayout from "../components/layout/main";
import { graphql } from "gatsby";
import { Box, SimpleGrid } from "@chakra-ui/core";
import PlaceCarouselWrapper from "../components/places/placeCarousel";
import { SecondaryHeading } from "../components/headings";
import MarkDown from "../components/MarkDown";
import CategoryList from "../components/categories/categoryList";
import LinkList from "../components/links/linkList";
import LineAddress from "../components/venues/lineAddress";
import VenueMap from "../components/venues/venueMap";
import SEO from "../components/SEO";
import ProductList from "../components/products/productList";
import { useIntl } from "gatsby-plugin-intl";
import { getAttributeTranslated } from "../util/entities";
import { useLinks } from "../util/places";
import { useRegisterScreen } from "../util/firebaseFunctions"

export default ({ data }) => {
  const intl = useIntl();
  if (data.place.name) {
    useRegisterScreen(data.place.name);
    const photos = !!data.place.photos
      && data.place.photos.map(photo => photo.reference);
    const [venue, setVenue] = React.useState(null);
    const { venues } = data.place;

    const links = useLinks(data.place, venue);

    React.useEffect(() => {
      if (venues) {
        setVenue(venues[0]);
      }
    }, [venues]);

    const changeVenue = (venue) => {
      setVenue(venue);
    };
    const location = !!venue ? venue.location : null;
    const image = !!photos ? photos[0] : null;
    const details = getAttributeTranslated(data.place, 'details', intl.locale);
    const title = getAttributeTranslated(data.place, 'name', intl.locale);
    return (
      <MainLayout>
        <SEO
          description={details}
          image={image}
          pathname={data.place.fields.slug}
          title={title}
          location={location}
          type="place"
        />
        <Box maxWidth={750} ml="auto" mr="auto">
          <PlaceCarouselWrapper
            photos={photos}
            mt={-4}
            ml={[-4, -4, 0, 0]}
            mr={[-4, -4, 0, 0]}
          />
        </Box>
        <Box>
          <CategoryList
            categories={data.place.categories}
            mt={[4, 4, 8, 8]}
            d="table"
            textAlign="center"
            mx="auto"
            delivery={data.place.delivery}
          />
          <SecondaryHeading textAlign="center" mt={8}>
            {title}
          </SecondaryHeading>
          <LineAddress
            mt={5}
            mb={4}
            venue={venue}
            venues={data.place.venues}
            changeVenue={changeVenue}
          />
          <LinkList textAlign="center" d="table" mx="auto" links={links} />
          <SimpleGrid
            minChildWidth="250px"
            spacing="40px"
          >
            <Box mt={8}>
              <MarkDown md={details} />
            </Box>
            <Box w="100%" mt={8} className="venueMap">
              <VenueMap venue={venue} />
            </Box>
          </SimpleGrid>
          {!!data.allProduct.nodes && (
            <ProductList mt={8} products={data.allProduct.nodes} showSections />
          )}
        </Box>
      </MainLayout>
    );
  }
  return null;
};

export const query = graphql`
  query($id: String!) {
    place(id: {eq: $id }) {
      categories {
        fields {
          slug
        }
        name
        name_en
        id
      }
      fields {
        slug
      }
      details
      details_en
      name
      delivery
      name_en
      venues {
        address
        location {
          lat
          lng
        }
        name
        phones {
          number
          phone
          whatsapp
        }
        schedule {
          friday {
            end
            start
          }
          holiday {
            end
            start
          }
          monday {
            end
            start
          }
          saturday {
            end
            start
          }
          sunday {
            end
            start
          }
          thursday {
            end
            start
          }
          tuesday {
            end
            start
          }
          wednesday {
            end
            start
          }
        }
      }
      id
      photos {
        reference
      }
      links {
        label
        type
        url
      }
    }
    allProduct(filter: {parent: {id: {eq: $id}}}, sort: {fields: index, order: ASC}) {
      nodes {
        name
        name_en
        id
        fields {
          slug
        }
        section
        section_en
        photo
      }
    }
  }
`;
